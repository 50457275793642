@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
html {
  overflow-y: inherit !important;
}
html body {
  font-size: 16px;
  line-height: 1.45;
  font-weight: normal;
  font-family: 'Gilroy', Arial, Helvetica, sans-serif;
  color: #171e26;
  background: #f5f7f8;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.eot');
  src:
    url('./assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff'),
    url('./assets/fonts/Gilroy-Medium.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.eot');
  src:
    url('./assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Bold.woff') format('woff'),
    url('./assets/fonts/Gilroy-Bold.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.eot');
  src:
    url('./assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Regular.woff') format('woff'),
    url('./assets/fonts/Gilroy-Regular.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.eot');
  src:
    url('./assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-SemiBold.woff') format('woff'),
    url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.height-webkit-fill-available {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}
